import {Route, Routes} from "react-router-dom";
import Login from "./pages/Login";
import FirstAccess from "./pages/FirstAccess";
import Recovery from "./pages/Recovery";
import NotFound from "./pages/NotFound";
import Layout from "./pages/Layout";
import Dashboard from "./pages/Dashboard";
import NPS from "./pages/NPS";
import Scheduling from "./pages/Scheduling";
import Telemedicine from "./pages/Telemedicine";
import WaitingRoom from "./pages/WaitingRoom";
import Account from "./pages/Account";

const AppRoutes = () => {
    return (
        <Routes>
            <Route path={"/"} element={<Layout/>}>
                <Route index element={<Dashboard/>}/>
                <Route path={'scheduling'} element={<Scheduling/>}/>
                <Route path={'account'} element={<Account/>}/>
                <Route path={"waitingroom"} element={<WaitingRoom/>}/>
                <Route path={"video"} element={<Telemedicine/>}/>
                <Route path={"nps"} element={<NPS/>}/>
                <Route path={"*"} element={<NotFound/>}/>
            </Route>
            <Route path={"/"}>
                <Route index path={"/login"} element={<Login/>}/>
                <Route path={"/recovery"} element={<Recovery/>}/>
                <Route path={"*"} element={<NotFound/>}/>
            </Route>
        </Routes>
    )
}
export default AppRoutes;