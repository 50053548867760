import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import {FloatingLabel} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {Link, useNavigate} from "react-router-dom";
import React, {useState} from "react";
import api from "../helpers/api";
import Image from "react-bootstrap/Image";
import Logo from "../assets/images/sn-logo01.png"
import Alert from "react-bootstrap/Alert";
import InputMask from "react-input-mask";

const YEAR = new Date().getFullYear();
const Recovery = () => {
    const [taxNumber, setTaxNumber] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [error, setError] = useState(null);
    const [isConfirmation, setIsConfirmation] = useState(false)
    const [token, setToken] = useState("")

    const navigate = useNavigate();
    const handleSubmit = async () => {
        try {
            const endpoint = "app/usuario/senha/criar";
            const d = {};
            let confirmation = false;

            if (isConfirmation) {
                d.login = taxNumber;
                d.token = token;
            } else {
                d.login = taxNumber;
                d.newPassword = token;
            }

            const resp = await api.post(endpoint, d);
            if (!isConfirmation) {
                setIsConfirmation(true)
            } else {
                navigate('/');
            }
        } catch (err) {
            if (err.response) {
                const {errors} = {...err.response.data};
                Object.entries(errors).forEach(([key, value]) => {
                    setError(value);
                })
            }
        }
    }
    return (
        <Container className={'d-flex justify-content-center'}>
            <div className={'d-flex flex-column gap-3 mt-4'} style={{maxWidth: 480}}>
                <div className={'text-center'}>
                    <Image src={Logo} width={"75%"}/>
                    <h5 className='text-white'>Criar uma nova senha</h5>
                    <p className='text-secondary'>
                        Se esse for o seu primeiro acesso você poderá criar a sua senha por aqui. Caso você tenha
                        perdido sua senha atual é possível solicitar a criação de uma nova senha de acesso à sua conta.
                    </p>
                </div>
                {!isConfirmation &&
                    <>
                        <Form.Group>
                            {error && <Alert variant={'danger'}>{error}</Alert>}
                            <FloatingLabel label={"Digite o seu CPF"}>
                                <InputMask
                                    placeholder={'Digite o seu CPF'}
                                    mask="999.999.999-99"
                                    value={taxNumber}
                                    onChange={(e) => setTaxNumber(e.target.value)}
                                >
                                    {(inputProps) => <Form.Control type="text" {...inputProps} />}
                                </InputMask>
                            </FloatingLabel>
                            <Form.Text className="text-muted"></Form.Text>
                        </Form.Group>
                        <Form.Group>
                            <FloatingLabel label={"Digite sua nova senha"}>
                                <Form.Control type="password"
                                              placeholder="Digite sua nova senha"
                                              value={newPassword}
                                              id='newPassword'
                                              maxLength={6}
                                              onChange={(e) => setNewPassword(e.target.value)}/>
                            </FloatingLabel>
                            <Form.Text className="text-muted"></Form.Text>
                        </Form.Group>
                    </>
                }
                {isConfirmation && (
                    <Form.Group>
                        {isConfirmation &&
                            <Alert variant={'success'}>Código de confirmação enviado por e-mail e SMS</Alert>}
                        <FloatingLabel label={"Token"}>
                            <Form.Control type="text"
                                          placeholder="Digite o código"
                                          value={token}
                                          id='token'
                                          maxLength={6}
                                          onChange={(e) => setToken(e.target.value)}/>
                        </FloatingLabel>
                        <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>)
                }
                <Button className={"primary text-black"}
                        type="submit"
                        size={"lg"}
                        onClick={() => handleSubmit()}
                >
                    Criar Senha
                </Button>
                <Link className={'text-primary custom-a text-center my-3'} to={'/login'}>Voltar para login</Link>
                <small className={'text-secondary small text-center'}> © {YEAR} | Super N</small>
            </div>
        </Container>
    )
}

export default Recovery;