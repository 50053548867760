import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Card, FloatingLabel} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React, {useState} from "react";
import api from "../helpers/api";
import Logo from "../assets/images/sn-logo01.png"
import Image from "react-bootstrap/Image";
import Alert from "react-bootstrap/Alert";
import InputMask from "react-input-mask";
import {useAuth} from "../hooks/useAuth";
import {useNavigate} from "react-router-dom";

const Account = () => {
    const [newPassword, setNewPassword] = useState('');
    const [error, setError] = useState(null);
    const [inputToken, setInputToken] = useState(null)
    const [token, setToken] = useState("")
    const navigate = useNavigate();
    const {user} = useAuth();
    const handleChangePassword = async () => {
        if(inputToken)
        {
            try {
                const resp = await api.post('/plataforma/usuario/manipular-senha', {
                    "taxNumber": user.TaxNumber,
                    "action": "change",
                    "token": `${token}`
                });
                const {data} = {...resp.data};
                setInputToken(false);
                console.log(token);
                navigate("/");
            } catch (err) {
                if (err.response) {
                    const {errors} = {...err.response.data};
                    Object.entries(errors).forEach(([key, value]) => {
                        setError(value);
                    })
                }
                console.log(token);
            }
        }
        else
        {
            try {
                const resp = await api.post('/plataforma/usuario/manipular-senha', {
                    "taxNumber": user.TaxNumber,
                    "action": "change",
                    "newPassword": newPassword
                });
                const {data} = {...resp.data};
                setNewPassword(data);
                setInputToken("Enviamos um token para o número de celular cadastrado via SMS.")
                //console.log('Senha atualizada!', resp.data);
            } catch (err) {
                if (err.response) {
                    const {errors} = {...err.response.data};
                    Object.entries(errors).forEach(([key, value]) => {
                        setError(value);
                    })
                }
            }
        }
    }

    return (
        <>
            <Container className={"d-flex flex-column justify-content-center align-items-center p-5"}>
                <Row className={'custom-container'}>
                    <Col className={"mb-4 d-flex flex-column justify-content-center align-items-center"}>
                        <Image src={Logo} width={"50%"}/>
                    </Col>
                    <Card className={'p-4 bg-transparent border-2 border-dark-50'}>
                        <h5 className='text-white'>Trocar Senha</h5>
                        <span className='text-dark-25 mb-3'>Você receberá via SMS um Token para confirmar a nova senha.</span>
                        {!inputToken &&
                        <>
                            <Form.Group className="mb-3">
                                {error && <Alert variant={'danger'}>{error}</Alert>}
                                <Form.Text className="text-muted"></Form.Text>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <FloatingLabel label={"Nova senha"}>
                                    <Form.Control type="password"
                                                  value={newPassword}
                                                  id='newPassword'
                                                  maxLength={6}
                                                  onChange={(e) => setNewPassword(e.target.value)}/>
                                </FloatingLabel>
                                <Form.Text className="text-muted"></Form.Text>
                            </Form.Group>
                        </>
                        }
                        {inputToken &&
                            <Form.Group className="mb-3">
                                {inputToken && <Alert variant={'success'}>{inputToken}</Alert>}
                                <FloatingLabel label={"Token"}>
                                    <Form.Control type="text"
                                                  placeholder="Digite o código"
                                                  value={token}
                                                  id='token'
                                                  maxLength={6}
                                                  onChange={(e) => setToken(e.target.value)}/>
                                </FloatingLabel>
                                <Form.Text className="text-muted"></Form.Text>
                            </Form.Group>
                        }
                        <Form.Group className={"d-grid py-2"}>
                            <Button variant="primary" type="submit" className={"bg-primary"} size={'md'}
                                    onClick={() => handleChangePassword()}>
                                Trocar Senha
                            </Button>
                        </Form.Group>
                        <Button variant={"outline-danger"}
                                onClick={() => {navigate('/')}}>Voltar</Button>
                    </Card>
                </Row>
            </Container>
        </>
    )

}

export default Account;