import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const NotFound = () =>
{
    return(
        <>
            <Container>
                <Row>
                    <Col>
                        <h1 className={"tok"}>404 😷</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>A página não encontrada.</p>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default NotFound;