import {Card, Dropdown, InputGroup } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {MdDateRange} from "react-icons/md";
import {BsSearch} from "react-icons/bs";
import React, {useEffect, useState} from "react";
import moment from "moment";
import {Link, useNavigate} from "react-router-dom";
import api from "../helpers/api";
import CustomPagination from "../components/CustomPagination";
import {FiLogOut} from "react-icons/fi";
import {useAuth} from "../hooks/useAuth";
import {BiUser} from "react-icons/bi";

const Dashboard = () => {
    const currentDate = moment().format('DD-MM-YYYY');
    const [appointmentDate, setAppointDate] = useState([]);
    const [ScheduleAt, setScheduleAt] = useState(currentDate);
    const [page, setPage] = useState(1);
    const [date, setDate] = useState("");
    const navigate = useNavigate();
    const {logout, user} = useAuth();
    const getAppointments = async () => {
        try {
            const params = {
                dateAppointment: ScheduleAt, Status: "1,6", taxNumber: user.Login
            };
            const resp = await api.get('video/atendimentos', {
                params,
            })
            const {data} = {...resp.data};
            setAppointDate(data);
        } catch (err) {
            //console.error("Erro ao obter atendimentos", err);
        }
    }
    const handleLogout = () => {
        logout();
    }

    useEffect(() => {
        getAppointments().then();

        appointmentDate.filter((item, index) => {
            if (index >= (page - 1) * 10 && index < page * 10) {
                //console.log(item)
                return item;
            }
        })
    }, []);

    return (
        <>
        <Container>
            <Row className={"text-center p-4 text-white"}>
                <Col>
                    <h1>Agendamentos</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className={"pt-4 px-1 bg-transparent border-2 border-dark-50"}>
                            <Container>
                                <Row>
                                    <Col xs={5}>
                                        <InputGroup>
                                            <InputGroup.Text className={'bg-transparent text-secondary'}>Buscar</InputGroup.Text>
                                            <Form.Control id={"buscar"} value={date}
                                                          onChange={() => {
                                                              setDate(document.querySelector("#buscar").value)
                                                          }} onSubmit={() => {
                                                setDate(document.querySelector("#buscar").value)
                                            }}/>

                                            <Button
                                                className="d-flex align-items-center justify-content-center bg-primary text-black"
                                                onClick={() => {
                                                setDate(document.querySelector("#buscar").value)
                                            }}>
                                                        <BsSearch/>
                                            </Button>
                                        </InputGroup>
                                    </Col>
                                    <Col xs={5}>
                                        <Link to={"/scheduling"}
                                              className={"d-grid rounded text-decoration-none"}>
                                            <Button
                                            className={"d-flex align-items-center justify-content-center bg-primary text-black gap-2"}> <MdDateRange/> Agendar</Button></Link>
                                    </Col>
                                    <Col xs={2}>
                                        <Dropdown>
                                            <Dropdown.Toggle variant={'outline-primary'} className={'w-100'}>
                                               Menu
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className={'border-1 border-light'}>
                                                    <Dropdown.Item href={'/account'} >
                                                        <Button
                                                            className={'d-flex align-items-center justify-content-center gap-2 w-100'}
                                                            variant={'outline-dark-25'}>
                                                            <BiUser/>Alterar Senha
                                                        </Button>
                                                    </Dropdown.Item>
                                                    <Dropdown.Item onClick={handleLogout}>
                                                        <Button
                                                            className={'d-flex align-items-center justify-content-center gap-2 w-100'}
                                                            variant={'outline-dark-25'}>
                                                            <FiLogOut/>Desconectar
                                                        </Button>
                                                    </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Col>
                                </Row>
                            </Container>
                        <Col className={"pt-2"}>
                            {appointmentDate.length <= 0 &&
                                <div className={"pt-5 mt-5 d-flex justify-content-center"}>
                                        <span className={"text-secondary"}>Você ainda não agendou nenhum atendimento.</span>
                                </div>
                            }
                            {appointmentDate.length > 0 &&
                                <CustomPagination outData={appointmentDate} changeDate={date}/>
                            }
                        </Col>
                    </Card>
                </Col>
            </Row>
        </Container>
    </>)
}

export default Dashboard;