import axios from "axios";

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URI,
    headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'Basic ' + process.env.REACT_APP_BASIC,
    },
})

export default api;