import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import {Card, FloatingLabel, InputGroup} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {Link, useNavigate} from "react-router-dom";
import React, {useState} from "react";
import api from "../helpers/api";
import Image from "react-bootstrap/Image";
import Logo from "../assets/images/sn-logo01.png"
import Alert from "react-bootstrap/Alert";
import InputMask from "react-input-mask";

const YEAR = new Date().getFullYear();
const FirstAccess = () => {
    const [taxNumber, setTaxNumber] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [error, setError] = useState(null);
    const [isConfirmation, setIsConfirmation] = useState(false)
    const [token, setToken] = useState("")

    const navigate = useNavigate();
    const handleSubmit = async () => {
        try {
            const endpoint = "app/usuario/senha/criar";
            const d = {};
            let confirmation = false;

            if (isConfirmation) {
                d.login = taxNumber;
                d.token = token;
            } else {
                d.login = taxNumber;
                d.newPassword = token;
            }

            const resp = await api.post(endpoint, d);
            if (!isConfirmation) {
                setIsConfirmation(true)
            } else {
                navigate('/');
            }
        } catch (err) {
            if (err.response) {
                const {errors} = {...err.response.data};
                Object.entries(errors).forEach(([key, value]) => {
                    setError(value);
                })
            }
        }
    }
    return (
        <>
            <Container className={"d-flex flex-column justify-content-center align-items-center  p-5"}>
                <Row className={'custom-container'}>
                    <Col className={"mb-4 d-flex flex-column justify-content-center align-items-center"}>
                        <Image src={Logo} width={"75%"}/>
                        <h5 className='text-white'>Primeiro Acesso</h5>
                        <span className='text-secondary'>
                            Se esse for o seu primeiro acesso, é necessário criar uma senha contendo 6
                            números para acessar a sua conta.
                        </span>
                    </Col>

                    {!isConfirmation &&
                        <>
                            <Form.Group className="mb-3">
                                {error && <Alert variant={'danger'}>{error}</Alert>}
                                <FloatingLabel label={"CPF"}>
                                    <InputMask
                                        mask="999.999.999-99"
                                        value={taxNumber}
                                        onChange={(e) => setTaxNumber(e.target.value)}
                                    >
                                        {(inputProps) => <Form.Control type="text" {...inputProps} />}
                                    </InputMask>
                                </FloatingLabel>
                                <Form.Text className="text-muted"></Form.Text>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <FloatingLabel label={"Senha"}>
                                    <Form.Control type="password"
                                                  placeholder="Digite sua nova senha"
                                                  value={newPassword}
                                                  id='newPassword'
                                                  maxLength={6}
                                                  onChange={(e) => setNewPassword(e.target.value)}/>
                                </FloatingLabel>
                                <Form.Text className="text-muted"></Form.Text>
                            </Form.Group>
                        </>
                    }
                    {isConfirmation && (
                        <Form.Group className="mb-3">
                            {isConfirmation &&
                                <Alert variant={'success'}>Código de confirmação enviado por e-mail e SMS</Alert>}
                            <FloatingLabel label={"Token"}>
                                <Form.Control type="text"
                                              placeholder="Digite o código"
                                              value={token}
                                              id='token'
                                              maxLength={6}
                                              onChange={(e) => setToken(e.target.value)}/>
                            </FloatingLabel>
                            <Form.Text className="text-muted"></Form.Text>
                        </Form.Group>)
                    }

                    <Form.Group className={"d-grid py-2"}>
                        <Button className={"primary text-black"} type="submit" size={"md"}
                                onClick={() => handleSubmit()}
                        >
                            Criar Senha
                        </Button>
                    </Form.Group>
                    <Col className={"d-flex flex-column justify-content-center align-items-center mt-4"}>
                        <a className={'text-secondary custom-a'} href={'/login'}>Voltar para login</a>
                        <a className={'text-secondary custom-a'} href={'/recovery'}>Recuperar Senha</a>
                        <span className={'mt-2 text-secondary'}> © {YEAR} | Super N</span>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default FirstAccess;