import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import {Card, ToastContainer} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Pana from "../assets/images/pana-sn.svg";
import Image from "react-bootstrap/Image";
import {useNavigate} from "react-router-dom";
import React, {useState} from "react";
import api from "../helpers/api";
import CustomToast from "../components/CustomToast";

const NPS = () =>
{
    const [scale, setScale] = useState();
    const [toast, setToast] = useState({})
    const navigate = useNavigate();

    const handleSubmit = async () => {
        setToast({show: false, delay: 3000})
        try {
            const params = {
                escala: scale,
                contextoId: 1
            };
            const resp = await api.post(`saude/nps`, params);
            const {data} = resp.data;
            setScale(data);
            //console.log(data);
            navigate('/');
        } catch (err) {
            //console.error('Erro no post', err);
        }
    }

    return(
        <>
            <div>
                <ToastContainer id={"toastcontainer"} position={"top-center"} className={"p-3"} style={{zIndex: 1}}>
                    {toast.show && <CustomToast delay={toast.delay} title={toast.title} content={toast.content} variant={toast.variant} show={toast.show} isHTML={toast.isHTML}/>}
                </ToastContainer>
            </div>
            <Container>
                <Row className={"text-center text-white p-2 mb-2"}>
                    <Col>
                        <h1>Pesquisa de Satisfação</h1>
                    </Col>
                </Row>
                <Row>
                    <Col sm={{span: 8, offset: 2}}>
                        <Card className={"pb-1 pt-3 d-flex justify-content-center border-1 border-primary bg-transparent "}>
                                <Row className={'justify-content-center align-items-center'}>
                                    <Col style={{maxWidth: '400px'}}>
                                        <h6 className={'fw-bold text-secondary'}>Por favor, escolha um número de 1 a 10 para indicar o quão satisfeito você está com a sua experiência de teleconsulta, onde 1 significa insatisfação total e 10 significa satisfação total.</h6>
                                    </Col>
                                </Row>
                                <Row className={"pb-5"}>
                                    <Col className={"d-flex justify-content-center"}>
                                        <Image src={Pana} style={{maxWidth: '440px', width: "100%"}}/>
                                    </Col>
                                </Row>
                                    <Col xs={{span: 10, offset: 1}}  className={"d-flex flex-column justify-content-center align-items-center gap-4 py-2"}>
                                                <Form.Select className={"col-10 border-2 border-primary text-white"} aria-label={"Avalie"} style={{width: '300px'}}
                                                value={scale}
                                                             onChange={(e) => setScale(e.target.value)}
                                                >
                                                    <option value={''}>Avalie</option>
                                                    <option value={"10"}>10</option>
                                                    <option value={"9"}>9</option>
                                                    <option value={"8"}>8</option>
                                                    <option value={"7"}>7</option>
                                                    <option value={"6"}>6</option>
                                                    <option value={"5"}>5</option>
                                                    <option value={"4"}>4</option>
                                                    <option value={"3"}>3</option>
                                                    <option value={"2"}>2</option>
                                                    <option value={"1"}>1</option>
                                                </Form.Select>
                                                <Button variant={'outline-primary'} onClick={handleSubmit} size='lg' style={{width: '300px'}}>Enviar</Button>
                                    </Col>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default NPS;