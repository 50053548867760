import React from 'react';
import AppRoutes from "./AppRoutes";
import AuthProvider from "./hooks/useAuth";
function App() {
    return (
        <AuthProvider initialPath='/' loginPath='/login'>
            <AppRoutes/>
        </AuthProvider>
    );
}

export default App;
