import {Toast} from "react-bootstrap";
import React, {useEffect, useState} from "react";

/**
 * @author Jonas Santos Menezes
 * @param show = exibir toast
 * @param variant = bootstrap-variant
 * @param title = título do toast
 * @param subtitile = usado para exibir informações extras, como tempo passado
 * @param content = texto adicional no corpo do toast
 * @param isHTML = flag para exibir se o content será renderizado como html
 * @param delay = define quanto tempo o toast será exibido e quanto tempo para executar o then
 * @param then = função para executar
 * @param className = substitui o className do toast
 * @returns {Element}
 * @constructor
 */
const CustomToast = ({show = true, variant = "", title = "Ação concluída.", subtitle, content = "", className = "", isHTML = false, delay = 0, then = () => {}}) =>
{
    const [showToast, setShowToast] = useState(true)
    const [autoHide, setAutoHide] = useState(false)

    useEffect(() => {
        setShowToast(show)
        if(delay > 0)
        {
            setAutoHide(true)
            then()
            setTimeout(then(), delay);
        }
    }, []);

    return (
        <>
            <Toast bg={variant} className={className} show={showToast} onClose={() => {setShowToast(!showToast)}} delay={delay} autohide={autoHide}>
                <Toast.Header>
                    <span className={"fw-bold me-auto"}>{title}</span>
                    {subtitle && <span className={"small"}>{subtitle}</span>}
                </Toast.Header>
                {content !== "" &&
                    <Toast.Body>
                        {isHTML && <div dangerouslySetInnerHTML={{__html: content}}></div>}
                        {!isHTML && <span>{content}</span>}
                    </Toast.Body>
                }
            </Toast>
        </>
    );
}

export default CustomToast;