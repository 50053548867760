import {Card, InputGroup, Modal, Spinner, ToastContainer} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, {useEffect, useState} from "react";
import moment from "moment";
import api from "../helpers/api";
import CustomToast from "../components/CustomToast";
import 'moment/locale/pt-br';
import {useNavigate} from "react-router-dom";
import {BiArrowBack} from "react-icons/bi";
import {useAuth} from "../hooks/useAuth";

const Scheduling = () => {
    const [hours, setHours] = useState([]);
    const [toast, setToast] = useState({})
    const [modal, setModal] = useState(false)
    const [confirm, setConfirm] = useState(true)
    const [schedule, setSchedule] = useState(0)
    const [currentDate, setCurrentDate] = useState("2023-12-31T23:59");
    const [ loading, setLoading] = useState(true);
    const { user } = useAuth()
    const formattedDate = moment(currentDate).format('dddd, D [de] MMMM [de] YYYY');
    const navigate = useNavigate();
    const getHours = async () => {
        try {
            const resp = await api.get('video/atendimentos/horarios')
            const {data} = resp.data;
            setHours(data.splice(0, 48));
        } catch (err) {
            //console.error("Erro ao obter horários", err);
        } finally {
            setLoading(false);
        }
    }
    const handleConfirm = (value) => {
        //Guarda o horário de value na variável schedule e em seguida chama o modal lá
        setSchedule(value);
        setConfirm(true);
        setTimeout(setModal(true), 250);
    }

    const formattedSchedule = moment(schedule).format('dddd, D [de] MMMM [de] YYYY, [às] HH:mm');

    const handleClick = async () => {
        setToast({show: false, delay: 3000})
        try {
            setModal(false)
            if (confirm) {
                //console.log("Criando agendamento")
                const resp = await api.post('video/atendimento', {
                    "dateAppointment": schedule,
                    "taxNumber": user.Login
                });
                const {data} = resp.data;
                setToast({
                    show: true,
                    title: "Sucesso",
                    variant: "success",
                    content: "Consulta agendada com sucesso.",
                    delay: toast.delay
                })
                setTimeout(() => {
                    navigate("/");
                }, 4000);
            }
        } catch (err) {
            const text = err.response.data.errors[0]
            setToast({show: true, variant: "danger", title: "Erro", content: text, delay: 5000})
        }
    }

    useEffect(() => {
        getHours().then();
        setCurrentDate(moment().format("yyyy-MM-DD"))
        // document.querySelector("#col-app").className = "";
    }, []);

    return (
        <>
            <Modal
                show={modal} onHide={() => {
                setModal(false)
            }}>
                <Modal.Header closeButton className={'bg-dark-50 text-white'}>
                    <Modal.Title >Confirmar agendamento</Modal.Title>
                </Modal.Header>
                <Modal.Body className={'text-white bg-dark-50'}>
                    <span>Deseja confirmar sua consulta para <strong>{formattedSchedule} ?</strong></span>
                </Modal.Body>
                <Modal.Footer className={'bg-dark-50'}>
                    <Button
                            variant={'outline-primary'}
                            onClick={() => {
                            setTimeout(() => {
                            setConfirm(true)
                            handleClick()
                        }, 500);
                    }}>Sim</Button>
                    <Button
                        variant={'outline-danger'}
                        onClick={() => {
                        setModal(false)
                        setConfirm(false)
                    }}>Não</Button>
                </Modal.Footer>
            </Modal>
            <div className={"sticky-bottom"}>
                <ToastContainer id={"toastcontainer"} position={"top-center"} className={"p-3"} style={{zIndex: 1}}>
                    {toast.show && <CustomToast delay={toast.delay} title={toast.title} content={toast.content} variant={toast.variant} show={toast.show} isHTML={toast.isHTML}/>}
                </ToastContainer>
            </div>
            <Container sm={{offset: 2, span: 8}}>
                <Row className={"text-center text-white"}>
                    <Col>
                        <h1>Teleconsulta</h1>
                        <p>Pronto atendimento realizado por vídeo.</p>
                    </Col>
                </Row>
                <Row className={"text-center fw-bold text-secondary"}>
                    <span>{formattedDate}</span>
                </Row>
                <Row>
                    <Col>
                        <Container>
                            <Row>
                                <Col className={"text-center text-white"}>
                                    <h3>Escolha um horário</h3>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Card className={"mt-2 shadow p-sm-4 p-md-5 border-2 border-dark-50 bg-transparent"}>
                                        {loading? (
                                            <div className={'d-flex justify-content-center align-items-center'}>
                                                    <Spinner animation="border" role="status" >
                                                        <span className="visually-hidden">Carregando...</span>
                                                    </Spinner>
                                            </div>
                                        ) : (
                                            <Container>
                                                <Row>
                                                    {hours.map((hour) => (
                                                        <Col xs={4} sm={3} md={2}
                                                             className={"my-1 p-0 d-grid justify-content-center"}
                                                             key={`col-${hour}`}>
                                                            <Button
                                                                key={hour}
                                                                variant={'outline-primary'}
                                                                onClick={() => handleConfirm(hour)}>
                                                                {moment(hour).format('HH:mm')}
                                                            </Button>
                                                        </Col>
                                                    ))}
                                                </Row>

                                            </Container>
                                        )}
                                    </Card>
                                </Col>
                            </Row>
                            <div className={"d-flex flex-column align-items-center py-2"}>
                                <Button variant={"outline-danger"}
                                        style={{width: '240px'}}
                                        onClick={() => {navigate("/")}}>Voltar</Button>
                            </div>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Scheduling;