import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import {Card, FloatingLabel} from "react-bootstrap";
import {Link, redirect, useNavigate} from "react-router-dom";
import {useContext, useState} from "react";
import Logo from "../assets/images/sn-logo01.png"
import api from "../helpers/api";
import {useAuth} from "../hooks/useAuth";
import InputMask from 'react-input-mask';

const YEAR = new Date().getFullYear();
const Login = () => {
    const [taxNumber, setTaxNumber] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const {login} = useAuth();
    const handleLogin = async () => {
        try {
            const resp = await api.post('app/usuario/autenticar', {
                "login": taxNumber, "password": password,
            });
            const {data} = {...resp.data}
            login(data);
            navigate('/');
        } catch (err) {
            if (err.response) {
                const {errors} = {...err.response.data};
                Object.entries(errors).forEach(([key, value]) => {
                    setError(value);
                })
            }
        }
    }

    return (
        <Container className={'d-flex justify-content-center'}>
            <div className={'d-flex flex-column gap-3 mt-4'} style={{maxWidth: 480}}>
                <div className={'text-center'}>
                    <Image src={Logo} className={'img-fluid w-75'}/>
                    <h5 className={'text-white my-4'}>Atendimento por Telemedicina</h5>
                </div>
                <Form.Group>
                    {error && (<Alert variant={'danger'}>{error}</Alert>)}
                    <FloatingLabel label={"CPF"}>
                        <InputMask
                            placeholder={'CPF'}
                            mask="999.999.999-99"
                            id='cpf'
                            value={taxNumber}
                            onChange={(e) => setTaxNumber(e.target.value)}
                        >
                            {(inputProps) => <Form.Control type="text" {...inputProps} />}
                        </InputMask>
                    </FloatingLabel>
                    <Form.Text className="text-muted"></Form.Text>
                </Form.Group>
                <Form.Group>
                    <FloatingLabel label={"Senha"}>
                        <Form.Control type="password" placeholder="Digite sua senha"
                                      id='password'
                                      value={password}
                                      maxLength={6}
                                      onChange={(e) => setPassword(e.target.value)}
                        />
                    </FloatingLabel>
                    <Form.Text className="text-muted"></Form.Text>
                </Form.Group>
                <Button className={'primary text-black'}
                        type="submit"
                        size={"lg"}
                        onClick={() => handleLogin()}>
                    Entrar
                </Button>
                <Link className={'text-primary custom-a text-center my-3'} to={'/recovery'}>Criar/Recuperar senha</Link>
                <small className={'text-center small'}> © {YEAR} | TokSaúde</small>
            </div>
        </Container>
    )
}
export default Login;