import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useAudioRecorder} from "react-audio-voice-recorder";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {BsCameraVideo} from "react-icons/bs";
import Button from "react-bootstrap/Button";
import {LiveAudioVisualizer} from "react-audio-visualize";
import {Card, Modal} from "react-bootstrap";

const MediaTest = ({token}) => {
    const navigate = useNavigate()
    const [step, setStep] = useState(0);
    const [modal, setModal] = useState(false);
    const recorder = useAudioRecorder();
    const getCam = () => {
        let constraints = {
            video: true,
        }
        navigator.mediaDevices.getUserMedia(constraints).then(function (mediaStream) {
            let video = document.querySelector('video');
            video.srcObject = mediaStream;
            video.play().then(() => {
            });
        }).catch(function (err) {
            console.log("Erro na câmera" + err.message);
        });
    }

    const handleNextStep = () => {
        setStep((prevState) => prevState + 1);
    }
    const startAudioRecording = () => {
        try {
            if (recorder && recorder.startRecording) {
                recorder.startRecording();
            }
            setTimeout(() => {
                navigate('/video', {state: {token}});
            }, 3000);
        } catch (e) {
        }
    };

    useEffect(() => {
        getCam();
    }, []);

    useEffect(() => {
        if (step === 2) {
            startAudioRecording();
        }
    }, [step]);

    return (
        <>
            {modal &&
                <Modal show={modal} onHide={() => {
                    setModal(false)
                }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Ops!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col className={'text-center text-white'}>
                                <p>Caso não consiga passar no teste, tente acessar de outro dispositivo ou entre em
                                    contato
                                    com o suporte através do chat, no canto inferior direito do Painel Médico. Você pode
                                    também <a>acessar o nosso FAQ</a></p>
                            </Col>
                        </Row>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button className={"bg-tok fw-bold w-100"} onClick={() => {
                            setModal(false)
                        }}>OK!</Button>
                    </Modal.Footer>
                </Modal>}
            {step === 0 &&
                <Container>
                    <Row>
                        <Col>
                            <h4 className={"text-primary text-center pt-5"}>Seja Bem vindo (a)!</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h6 className={"text-center text-white"}>Para assegurar a excelência do atendimento,
                                realizaremos
                                um teste do seu dispositivo como uma etapa fundamental antes de inicial o
                                atendimento.</h6>
                        </Col>
                    </Row>
                    <Card className={"my-3 py-5 px-4 border-2 border-primary bg-transparent  text-center"}>
                        <div id={'video-test-container'}>
                            <video/>
                        </div>
                    </Card>
                    <Row>
                        <Col className={"d-flex justify-content-center align-items-center text-white"}>
                            <h6>Você se visualiza na janela acima?</h6>
                        </Col>
                    </Row>
                    <div className={"d-flex flex-row justify-content-center align-items-center py-2 gap-2"}>
                        <Button className={'fw-bold'}
                                variant={"outline-danger"}
                                style={{width: '300px'}}
                                onClick={() => {
                                    setModal(true)
                                }}>Não</Button>

                        <Button className={'fw-bold'}
                            variant={"outline-primary"}
                                style={{width: '300px'}}
                                onClick={() => {
                                    handleNextStep()
                                }}>Sim</Button>
                    </div>
                    <div className={"d-flex flex-column align-items-center"}>
                        <Button variant={"outline-danger"}
                                style={{width: '240px'}}
                                onClick={() => {
                                    navigate("/")
                                }}>Cancelar</Button>
                    </div>
                </Container>
            }
            {step === 1 &&
                <Container className={'pt-5'}>
                    <Row>
                        <Col>
                            <h4 className={"text-primary text-center"}>Agora vamos testar o seu microfone!</h4>
                        </Col>
                    </Row>

                        <Card className={"my-3 py-5 px-4 border-2 border-primary bg-transparent  text-center"}>
                        <Col className={"p-5 text-white"}>
                            <p>Diga algo para validar o funcionamento do dispositivo.</p>
                        </Col>
                        </Card>

                    <Row className={"d-flex justify-content-center"}>
                        <Col xs={6} className={"d-grid gap-2"} style={{width: '300px'}}>
                            <Button  variant={"outline-primary"} onClick={() => {
                                handleNextStep()
                            }}>Vamos lá!</Button>
                            <Button  variant={"outline-danger"} onClick={() => {
                                navigate("/")
                            }}>Cancelar</Button>
                        </Col>
                    </Row>
                </Container>
            }
            {step === 2 &&
                <Container className={'pt-5'}>
                    <Row>
                        <Col>
                            <h4 className={"text-center text-primary"}>Vamos testar o seu microfone!</h4>
                            <p className={'text-center text-white'}>Diga algo para validar o funcionamento do dispositivo.</p>
                        </Col>
                    </Row>
                    <Card className={"my-3 py-5 px-4 border-2 border-primary bg-transparent text-center"}>
                        <Col className={"p-5"}>
                            <div>
                                {recorder.mediaRecorder && (
                                    <LiveAudioVisualizer
                                        mediaRecorder={recorder.mediaRecorder}
                                        width={720}
                                        height={240}
                                        barColor={'white'}
                                    />
                                )}
                            </div>
                        </Col>
                    </Card>
                    <Row>
                        <Col className={"text-center text-white"}>
                            <p>Caso não consiga fazer o teste, tente acessar de outro dispositivo ou entre em
                                contato
                                com o suporte</p>
                        </Col>
                    </Row>
                </Container>
            }
        </>
    )
}

export default MediaTest;