import {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import {Card, Pagination} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {BsCameraVideo} from "react-icons/bs";
import {useNavigate} from "react-router-dom";
import {AiOutlineDown, AiOutlineUp} from "react-icons/ai";
import moment from "moment/moment";

const CustomPagination = ({outData = [{}], changeDate = ""}) =>
{
    const [pageCounter, setPageCounter] = useState(1);
    const [data, setData] = useState([{}]);
    const [pagination, setPagination] = useState();
    const [sort, setSort] = useState(false);
    const [date, setDate] = useState("");
    const navigate = useNavigate();

    /**
     * @description Faz a comparação de duas strings retirando ":" e "-" retornando verdadeiro ou falso
     * @param string1
     * @param string2
     * @returns {boolean}
     */
    const cleanString = (string1, string2) =>
    {
        if(string1.toString().replaceAll(":","").replaceAll("-","").includes(string2.replaceAll("-","").replaceAll(":","")))
        {
            return true;
        }
        else
        {
            return false;
        }
    }
    const handleChange = () =>
    {
        setData(outData)
        const newdata = outData.filter((item) => {
            if(cleanString(item["ScheduleAt"], changeDate))
            {
                return item;
            }
        })
        setPagination(BuildPagination(pageCounter, newdata, navigate));
    }

    useEffect(() => {
        setData(outData);
        outData.reverse()
        setPagination(BuildPagination(pageCounter, outData, navigate))
        setDate(changeDate);
        handleChange();
    }, [outData, changeDate]);

    return(
        <>
            <Container>
                <Row xs={3}><Col><Button className={"bg-transparent border-0 text-white"} onClick={() => {
                    if(sort)
                    {
                        outData.reverse();
                        setPagination(BuildPagination(pageCounter, outData, navigate))
                        handleChange()
                    }
                    else
                    {
                        outData.reverse()
                        setPagination(BuildPagination(pageCounter, outData, navigate))
                        handleChange()
                    }
                    setSort(!sort)
                }}>Data {!sort && <AiOutlineDown />} {sort && <AiOutlineUp />}</Button></Col>
                </Row>
                <Row>
                    <Col>
                        <hr />
                    </Col>
                </Row>
            </Container>
            {pagination}
            {false
            }
        </>
    );
}

function BuildPagination(pageCounter, pageData, navigate)
{
    return(
        <>
            <Container>
                <Row>
                    <Col>
                        {pageData.map((item, index) => {
                            //Se o status for diferente de 1,6, não cria
                            if(item.Status != 1 && item.Status != 6)
                                return

                            if(index >= (pageCounter - 1) * 10 && index < (pageCounter) * 10)
                            {
                                return(
                                    <Row key={index}>
                                        <Col>
                                            <Card className={"py-2 my-2 mb-2 bg-transparent border-1 border-secondary"}>
                                                <Container>
                                                    <Row xs={2} className={"d-flex align-items-center"}  onClick={() => {navigate('/waitingroom' , {state: {AppointmentId: item.AppointmentId}, replace: true})}}>
                                                        <Col><strong style={{color: "white"}}>{moment(item.ScheduleAt).format('DD/MM/YYYY HH:mm')}</strong></Col>
                                                        <Col className={"d-grid align-items-center" }><Button
                                                            className={"bg-transparent text-white border-0 fw-bold d-flex align-items-center justify-content-center gap-1"}>
                                                            <BsCameraVideo className={"me-1"}/>Sala de Vídeo</Button></Col>
                                                    </Row>
                                                </Container>
                                            </Card>
                                        </Col>
                                    </Row>
                                );
                            }
                        })}
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default CustomPagination;